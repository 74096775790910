import React from 'react';
import {Route, Routes} from 'react-router-dom';

export default function Home() {
  return (
    <Routes>
      <Route path="/" element={<div>hello world</div>} />
      <Route path="/about" element={<div>about</div>} />
      <Route path="/company" element={<div>company</div>} />
      <Route path="/history" element={<div>history</div>} />
    </Routes>
  );
}

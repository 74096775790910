import './App.css';
import {useEffect} from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import {BrowserRouter} from 'react-router-dom';
import Home from './components/Home';

const FETCH_URL = "https://jsonplaceholder.typicode.com"

function App({children}) {
  useEffect(() => {
    const fetchData = async() => {
      fetch(`${FETCH_URL}/todos`)
        .then(res => res.json())
        .then(data => console.log(data))
    }
    fetchData();
  },[])
  return (
    <BrowserRouter>
      <Header />
      <Home />
      <Footer />
    </BrowserRouter>
  );
}

export default App;

// 필요한 부분(렌더링이 필요한 부분만 바꿔서 실행)
// react -> csr(client side rendering)
// nextjs -> ssr(server side rendering)


import React from 'react';
import {Link} from 'react-router-dom';

export default function Header() {
  return (
    <div style={{display: "flex", justifyContent: "space-between" }}>
      <div>
        <Link to="/">Logo</Link>
        <Link to="/about">About</Link>
        <Link to="/company">company</Link>
        <Link to="/history">history</Link>
      </div>
      <div>
        <a href="/">Logo</a>
        <a href="/about">About</a>
        <a href="/company">company</a>
        <a href="/history">history</a>
      </div>
    </div>
  );
}
